import React from 'react'

 const DesktopVideo = () => {
    function scrollDown() {
        document.getElementById("item-two").scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <section className="item-one-desktop">
            <video
                autoPlay
                muted={true}
                loop
                playsInline={true}
                preload="none"
                className="desktop-video">
                <source src={require('./bgvideo-cropped.mp4')} type="video/mp4" />
            </video>
            <span className="one-head">
                líderes en calidad de construcción e infraestructura
            </span>
            <button id="scroll-down" className="scrollDown" type="button" onClick={scrollDown}>&raquo;</button>
        </section>
    )
}

export default DesktopVideo