import React from "react";

import Building from "./Building"

const Servicios = () => {
    return(
        <div>
            < Building />
        </div>
        );
    };

export default Servicios;