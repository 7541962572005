import React from 'react'

export default function MobileVideo() {
  return (
    <section className="item-one-mobile">
    <img src={require('./bgvideo-cropped.gif')} alt='Main video' className='mobile-video'/>
    <span className="one-head">
        líderes en calidad de construcción e infraestructura
    </span>
</section>
  )
}
