import React from "react";

import Building from "./Building";


const Nosotros = () => {
    return(
        <div>
            < Building />
        </div>
    );
};

export default Nosotros;